import { ProductBase } from 'src/queries/elasticpath/getEPProducts';
import { WithRefurbishedDeviceCountryData } from 'src/queries/groq/getRefurbishedDeviceCountryData';
import * as ElasticPathTypes from 'src/typings/elasticPathTypes';

import { getCurrencyForCountry } from './formattingHelpers';

interface GetProductOldPriceProps {
  product?: ProductBase;
  country: string;
  cartItem?: ElasticPathTypes.CartItem;
}

export function getProductCurrentPrice({ product, country }: GetProductOldPriceProps) {
  const currency = getCurrencyForCountry(country)?.value;

  if (!product) {
    return null;
  }

  const price = product && product.price && product.price.find((p) => p.currency === currency);

  return price;
}

type GetCountrySellsRefurbishedProps = {
  country: string;
} & WithRefurbishedDeviceCountryData;

export function getCountrySellsRefurbished({
  country,
  refurbishedDeviceCountryData,
}: GetCountrySellsRefurbishedProps): boolean {
  return refurbishedDeviceCountryData.countries.includes(country);
}
