import React, { useContext } from 'react';
import { RefurbishedDeviceCountryData } from 'src/queries/groq/getRefurbishedDeviceCountryData';

export const RefurbishedDeviceCountryDataContext = React.createContext<RefurbishedDeviceCountryData | null>(null);
RefurbishedDeviceCountryDataContext.displayName = 'RefurbishedDeviceCountryDataContext';

export const useRefurbishedDeviceCountryData = () => {
  const refurbishedDeviceCountryData = useContext(RefurbishedDeviceCountryDataContext);
  if (refurbishedDeviceCountryData === null) {
    throw Error(
      'useRefurbishedDeviceCountryData did not find expected data, are we outside a RefurbishedDeviceCountryDataContext?'
    );
  }
  return refurbishedDeviceCountryData;
};
