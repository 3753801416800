import { useSelector } from 'react-redux';
import { getCountryNameForCodeDefaultToUS, usePriceFormatter } from 'src/helpers/formattingHelpers';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { formatShippingDetails } from 'src/hooks/useShippingTime';
import { State } from 'src/redux/reducers';

import {
  getShippingTypeFromSku,
  useDeprecatedSelectedShippingOption,
  useReduxCartShippingTimes,
} from '../../../hooks/useShippingTime';
import { Shipping as ReadOnlyShipping } from '../Summary/Shipping';

const Shipping = () => {
  const cart = useReduxCart();
  const country = useSelector((state: State) => state.countryDetector.country);
  const isB2bChecked = useSelector((state: State) => state.checkout.isB2bChecked);
  const priceFormatter = usePriceFormatter();

  const useNewShipping = true;
  const shippingTimeNewExpress = useReduxCartShippingTimes('express');
  const shippingTimeNewStandard = useReduxCartShippingTimes('standard');
  const selectedShippingOption = useDeprecatedSelectedShippingOption(cart.data);

  if (!selectedShippingOption) {
    return null;
  }

  const usePicker = (cart.data?.shipping.options.length ?? 0) > 1;

  if (!usePicker) {
    return (
      <ReadOnlyShipping shipping={selectedShippingOption} countryName={getCountryNameForCodeDefaultToUS(country)} />
    );
  } else {
    return (
      <div className="relative mt-[10px] mx-0 mb-20">
        <fieldset className="border-none p-0 m-0">
          <legend className="absolute w-[1px] h-[1px] overflow-hidden">Choose shipping option</legend>
          {cart.data?.shipping.options.map((it) => {
            const shippingTimeOld = isB2bChecked
              ? formatShippingDetails(it.delivery?.minDaysB2b, it.delivery?.maxDaysB2b)
              : formatShippingDetails(it.delivery?.minDays, it.delivery?.maxDays);
            const shippingTimeNew =
              getShippingTypeFromSku(it.sku) === 'express'
                ? shippingTimeNewExpress.shippingTime
                : shippingTimeNewStandard.shippingTime;
            const shippingTime = useNewShipping ? shippingTimeNew : shippingTimeOld;
            return (
              <div key={it.sku} className="flex items-center [&:not(:last-of-type)]:mb-[10px]">
                <input
                  type="radio"
                  value={it.sku}
                  id={`shipping-${it.sku}`}
                  className="appearance-none block h-[15px] w-[15px] bg-[url('/svgs/radio_button_2.svg')] bg-no-repeat bg-[length:15px_13px] bg-center mr-[10px] checked:bg-[url('/svgs/radio_button_active_2.svg')]"
                  checked={cart.data?.shipping?.selected?.sku === it.sku}
                  onChange={() => {
                    cart.actions.updateShipping({ shipping: it.sku });
                  }}
                />
                <label
                  htmlFor={`shipping-${it.sku}`}
                  className="contents w-fill items-center justify-between text-[0.875rem] font-medium"
                >
                  {it.name}{' '}
                  {shippingTime && <span className="font-book text-[0.75rem] mr-auto ml-[5px]">({shippingTime})</span>}
                  <span className="font-demi ml-auto">
                    {priceFormatter(it.price.amount, it.price.currency).formatted}
                  </span>
                </label>
              </div>
            );
          })}
        </fieldset>
      </div>
    );
  }
};

export default Shipping;
