import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_FOLIO } from 'src/constants';
import { useRefurbishedDeviceCountryData } from 'src/contexts/RefurbishedDeviceCountryDataContext';
import { DeviceVariant, FolioVariant, MarkerVariant } from 'src/data/productData';
import { getMPFPathUrlObject, isDeviceSku, isFolioSku, isMarkerSku } from 'src/helpers/MPFCartHelpers';
import { getCountrySellsRefurbished } from 'src/helpers/offerHelpers';
import * as MPFactions from 'src/redux/actions/managedPurchaseFlowActions';
import { State } from 'src/redux/reducers';
import { usePromotionBundleContext } from 'src/views/MPF/PromotionBundleContext';

type BundleConfiguration = [DeviceVariant, MarkerVariant, FolioVariant];

/**
 * This hook is only needed until we can support multiple connect plans in cart
 *
 * When removing this take the implementations of the different exposed functions
 * (editBundle, ...) and put them back in the components (i.e BundleItem, ...)
 *
 */
const findStep = (showActiveBundlePromotion: boolean, countrySellsRefurbished: boolean) => {
  if (showActiveBundlePromotion) {
    return 'promotion';
  }
  return countrySellsRefurbished ? 'device' : 'marker';
};

export const useBundle = (): {
  bundleControls: {
    editBundle: (sku: string, items: { sku: string }[]) => void;
  };
  configureAnotherBundle: (replace: boolean, showActiveBundlePromotion?: boolean) => void;
} => {
  const dispatch = useDispatch();
  const router = useRouter();

  const country = useSelector((state: State) => state.countryDetector.country);
  const { activePromotionBundle, isValidPromotionBundle } = usePromotionBundleContext();
  const refurbishedDeviceCountryData = useRefurbishedDeviceCountryData();
  const countrySellsRefurbished = getCountrySellsRefurbished({ country, refurbishedDeviceCountryData });
  const hasActiveBundlePromotion = !!activePromotionBundle;

  const editBundle = (sku: string, items: { sku: string }[]) => {
    dispatch(MPFactions.resetMPFState());
    dispatch(MPFactions.setMPFEditBundle(sku));
    const device = items.map((it) => it.sku).find(isDeviceSku);
    const marker = items.map((it) => it.sku).find(isMarkerSku);
    const folio = items.map((it) => it.sku).find(isFolioSku);

    const step = isValidPromotionBundle(sku) ? 'promotion' : countrySellsRefurbished ? 'device' : 'marker';

    router.push(getMPFPathUrlObject(step, device, marker || 'none', folio || 'none'));

    /**
     * TODO: Add when creating vertical MPF ab-test
     *
     * router.push('/store/configure/vertical?editing=' + sku);
     */
  };

  const configureAnotherBundle = async (replace: boolean, showActiveBundlePromotion: boolean) => {
    // reMarkable 2, Marker Plus and Premium Leather - Brown
    const defaultBundleConfiguration: BundleConfiguration = [
      'RM110',
      'RM212',
      // TODO: Improve support for promotion to support Type Folio when required
      DEFAULT_FOLIO.FOLIO,
    ];

    const path = getMPFPathUrlObject(
      findStep(showActiveBundlePromotion, countrySellsRefurbished),
      ...defaultBundleConfiguration
    );

    if (replace) {
      router.replace(path);
    } else {
      router.push(path);
    }
  };

  return {
    bundleControls: {
      editBundle: editBundle,
    },
    configureAnotherBundle: (replace = false, showActiveBundlePromotion?: boolean) => {
      // If flag not provided, default to value from PromotionBundleContext
      return configureAnotherBundle(replace, showActiveBundlePromotion ?? hasActiveBundlePromotion);
    },
  };
};
