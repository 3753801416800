import React from 'react';
import shortid from 'shortid';
import { isKlarnaSupportedCountry } from 'src/helpers/paymentHelpers';

import { Spinner } from '@remarkable/ark-web';
import { loadKlarnaScript } from './klarnaHelpers';

interface Props {
  value: number;
  country: string;
}

export default class KlarnaWidget extends React.Component<Props> {
  private id: string;

  constructor(props: Props) {
    super(props);
    this.load = this.load.bind(this);
    this.id = shortid.generate();
  }
  componentDidMount() {
    if (this.props.country) {
      this.load();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.country === null ||
      prevProps.country !== this.props.country ||
      prevProps.value !== this.props.value
    ) {
      this.load();
    }
  }

  load() {
    if (isKlarnaSupportedCountry(this.props.country)) {
      loadKlarnaScript({ country: this.props.country });
    }
  }

  render() {
    const { country, value } = this.props;

    if (!isKlarnaSupportedCountry(country)) return null;

    return (
      <div className="relative flex justify-left min-h-[40px] mt-[10px] mb-[22px] mx-0 m-0 mb-22">
        <div className="flex justify-center items-center">
          <div className="absolute w-full h-full flex items-center justify-center z-[-1]">
            <Spinner className="text-grayscale-gray-400" size="small" />
          </div>
          <klarna-placement
            data-key="credit-promotion-small"
            data-locale={`en-${country}`}
            data-purchase-amount={value}
          />
        </div>
      </div>
    );
  }
}
