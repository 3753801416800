import { Button, Link as ArkLink, Spinner } from '@remarkable/ark-web';
import { CartInteraction } from 'ampli-types';
import classNames from 'classnames';
import Link from 'next/link';
import { CaretRight, Plus } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AffirmWidget } from 'src/components/Affirm/AffirmWidget';
import { useCookieBarContext } from 'src/contexts/CookieBarContext';
import { useGTMProductsData } from 'src/contexts/GTMProductsDataContext';
import KlarnaWidget from 'src/helper-components/Klarna/klarnaWidget';
import { connectPPSku } from 'src/helpers/connectHelpers';
import {
  getCountryNameForCodeDefaultToUS,
  useFormattedLocalizedPrice,
  usePriceFormatter,
} from 'src/helpers/formattingHelpers';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useBundle } from 'src/hooks/cart/useBundle';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { useCountryData } from 'src/hooks/useCountryData';
import { setCartVisible } from 'src/redux/actions/storeActions2';
import { State } from 'src/redux/reducers';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';
import { tracker } from 'src/services/tracker';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { getTrackingProducts, getTrackingProductsTotal } from 'src/services/tracking/utils';
import { isMobile } from 'src/utils/isMobile';
import { getQuantityDiscount } from 'src/views/Cart/quantity-discount/utils';
import { useStaticConnectPrices } from 'src/views/MPF/hooks/useStaticConnectPrices';

import CartInfoBox from 'src/components/MPF/CartInfoBox';
import { usePromotionBundleContext } from 'src/views/MPF/PromotionBundleContext';
import { CartConnectInfo } from '../MpfCart/CartConnectInfo';
import Shipping from '../Shipping';
import { ItemsWithPromotions } from '../Summary/ItemsWithPromotions';
import { SummaryLines } from '../Summary/SummaryLines';

type CartContentProps = {
  openModal: () => void;
};

const useLastSelectedPromotionBundle = () => {
  const [selectedPromotionBundle, setSelectedPromotionBundle] = useState<string>();

  useEffect(() => {
    try {
      const value = localStorage.getItem('LAST_SELECTED_PROMOTION_BUNDLE');
      setSelectedPromotionBundle(value ?? undefined);
    } catch {}
  }, []);

  return selectedPromotionBundle;
};

export const CartContent = ({ openModal }: CartContentProps): JSX.Element => {
  const cart = useReduxCart();
  const { country, currency } = useCountryData();
  const lastSelectedPromotionBundle = useLastSelectedPromotionBundle();

  const cartEmpty = cart.helpers.getCartIsEmpty();
  const dispatch = useDispatch();

  const quantityDiscount = getQuantityDiscount(cart.data);
  const newDeviceQuantity = cart.helpers.getNumberOfNewDevicesInCart();
  const sanityCountryData = useSelector((state: State) => state.staticQuery.countryData);
  const countryData = getCountryInfoByValue(sanityCountryData, country);
  const connectPrices = useStaticConnectPrices(currency);

  const deviceLimit = countryData?.deviceLimit?.limit;

  const cartTotalFormatted = useFormattedLocalizedPrice(cart.data?.total.amount ?? 0);
  const gtmProductsData = useGTMProductsData();
  const trackingProducts = getTrackingProducts(cart.data, gtmProductsData);

  const { bundleControls, configureAnotherBundle } = useBundle();

  const priceFormatter = usePriceFormatter();

  const totalSavingsAmount = cart.helpers.getCartAllAppliedDiscounts();
  const totalSavings = useFormattedLocalizedPrice(totalSavingsAmount ?? 0);
  const totalSavingsString = totalSavingsAmount ?? 0 > 0 ? totalSavings : undefined;

  const referralCode = useSelector((state: State) => state.store2.referralCode);
  const { activePromotionBundle } = usePromotionBundleContext();

  const checkoutNavigation = async () => {
    pushGAEventToDataLayer(eventCategoryEnum.MPF_NAVIGATION, eventActionEnum.CTA, 'Checkout');

    if (quantityDiscount?.currentDiscountCode && newDeviceQuantity) {
      pushGAEventToDataLayer(
        eventCategoryEnum.CART_PAGE_NAVIGATION,
        eventActionEnum.QUANTITY_DISCOUNT,
        `Customer continues to checkout with a ${quantityDiscount.currentDiscountCode}% quantity discount on ${newDeviceQuantity} devices`
      );
    }
  };

  const closeCart = () => {
    dispatch(setCartVisible(false));
  };

  const currentBreakpoint: BP | null = useActiveBreakpoint();
  const { cookieBarDismissed } = useCookieBarContext();

  const checkoutHref = '/checkout';
  const formattedTaxPrice = useFormattedLocalizedPrice(cart.data?.tax.amount ?? 0);

  return (
    <>
      <div className="relative px-20 py-0 overflow-y-auto md:px-40">
        {cart.loading && (
          <div className="absolute top-0 left-0 z-10 w-full h-full text-center opacity-0 bg-grayscale-white animate-cart-spinner">
            <div className="flex justify-center pt-[10%]">
              <Spinner size="large" className="text-grayscale-gray-800" />
            </div>
          </div>
        )}
        {cart.data && !cartEmpty ? (
          <>
            <ItemsWithPromotions
              bundles={cart.data.bundles}
              items={cart.data.items.filter((item) => item.sku !== connectPPSku)}
              currency={cart.data.currency}
              promotionItems={cart.helpers.getPromotionItems(priceFormatter)}
              quantityDiscountProps={
                quantityDiscount && newDeviceQuantity
                  ? { quantityDiscount, deviceQuantity: newDeviceQuantity }
                  : undefined
              }
              withControls={bundleControls}
              indentContent
            />

            <CartInfoBox
              cart={cart}
              country={country}
              lastSelectedPromotionBundle={lastSelectedPromotionBundle}
              deviceLimit={countryData.deviceLimit}
              activeReferral={!!referralCode}
            />

            {cart.helpers.getNumberOfDevicesInCart() > 0 && (
              <CartConnectInfo showModal={() => openModal()} monthlyPrice={connectPrices.connect.current} />
            )}
            <div className="my-32">
              {!cart.helpers.getCartDeviceThresholdMet(deviceLimit) && (
                <Button
                  data-cy="configure-another-bundle"
                  onClick={async () => {
                    pushGAEventToDataLayer(
                      eventCategoryEnum.MPF_INTERACTION,
                      eventActionEnum.BUTTON,
                      'Add another bundle'
                    );
                    configureAnotherBundle(false, !!activePromotionBundle);
                    tracker.trackEvent(
                      new CartInteraction({
                        action: 'configure another bundle',
                        component_location: ComponentLocations.CART.BUNDLE,
                        cart_products: trackingProducts,
                        cart_value: getTrackingProductsTotal(trackingProducts),
                        link_source: '/store/configure',
                      })
                    );
                  }}
                  variant="secondary"
                >
                  <span>Add another bundle</span>
                  <Plus />
                </Button>
              )}
            </div>
            <SummaryLines
              shipping={<Shipping />}
              countryName={getCountryNameForCodeDefaultToUS(country)}
              totalPrice={cartTotalFormatted}
              taxStatus={cart.helpers.getTaxStatus(country, false)}
              formattedTaxPrice={formattedTaxPrice}
              totalSavings={totalSavingsString}
            />
            <>
              <KlarnaWidget value={cart.data.total.amount} country={country} />
              <AffirmWidget amount={cart.data.total.amount} />
            </>
          </>
        ) : (
          <div data-cy="cart-is-empty" className="flex flex-col items-center justify-center min-h-full">
            <p className="mt-0 mb-24 font-demi">The cart is empty</p>
            <Link href="/store/remarkable-2" passHref legacyBehavior>
              <Button
                data-cy="continue-shopping"
                onClick={() => {
                  closeCart();
                  tracker.trackEvent(
                    new CartInteraction({
                      action: 'close',
                      component_location: ComponentLocations.CART.CONTINUE_SHOPPING,
                      cart_products: trackingProducts,
                      cart_value: getTrackingProductsTotal(trackingProducts),
                    })
                  );
                }}
                as={'a'}
              >
                Continue shopping
              </Button>
            </Link>
          </div>
        )}
      </div>
      <footer
        className={classNames(
          'py-12 px-20 bg-grayscale-gray-800 text-grayscale-gray-50 flex justify-between min-h-[72px] md:pl-40 md:pr-40',
          {
            'mb-[42px]': !cookieBarDismissed && !isMobile(currentBreakpoint),
            'mb-56': !cookieBarDismissed && isMobile(currentBreakpoint),
          }
        )}
      >
        <ArkLink
          onClick={() => {
            closeCart();
            tracker.trackEvent(
              new CartInteraction({
                action: 'close',
                component_location: ComponentLocations.CART.CONTINUE_SHOPPING,
                cart_products: trackingProducts,
                cart_value: getTrackingProductsTotal(trackingProducts),
                link_source: checkoutHref,
              })
            );
          }}
          href="/store"
        >
          <span>Continue shopping</span>
        </ArkLink>
        {cart.data && !cartEmpty && (
          <Link href={checkoutHref} legacyBehavior>
            <Button
              data-cy="checkout"
              onClick={() => {
                //label same as text inside <span/> below
                checkoutNavigation();
                tracker.trackEvent(
                  new CartInteraction({
                    action: 'checkout clicked',
                    component_location: ComponentLocations.CART.FOOTER,
                    cart_products: trackingProducts,
                    cart_value: getTrackingProductsTotal(trackingProducts),
                    link_source: checkoutHref,
                  })
                );
              }}
              disabled={cart.helpers.getCartDeviceThresholdExceeded(deviceLimit)}
            >
              <span>Checkout</span>
              <CaretRight />
            </Button>
          </Link>
        )}
      </footer>
    </>
  );
};
