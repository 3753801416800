import { isKlarnaSupportedCountry } from 'src/helpers/paymentHelpers';

import { dataClientId, euSource } from './data';

const updateKlarna = async () => {
  if (window && window.KlarnaOnsiteService) {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({
      eventName: 'refresh-placements',
    });
    // console.log('Klarna loaded.');
    return true;
  }
  return false;
};

interface LoadKlarnaScriptProps {
  country: string;
}
export const loadKlarnaScript = async ({ country }: LoadKlarnaScriptProps) => {
  if (!isKlarnaSupportedCountry(country)) {
    return false;
  }
  const scriptId = 'rm-klarna-onsite-script';
  // Check for existing script
  const klarnaScript = document?.getElementById(scriptId) as any;
  if (klarnaScript) {
    const url = klarnaScript.src;
    const params = new URL(url).searchParams;
    const urlCountry = params.get('country');

    if (country !== urlCountry) {
      klarnaScript.parentNode.removeChild(klarnaScript);
    } else {
      return updateKlarna();
    }
  }

  // Add script to DOM
  const script = document.createElement('script');
  script.src = euSource;
  script.async = true;
  script.id = scriptId;
  script.setAttribute('data-client-id', dataClientId);

  document.body.appendChild(script);
  let loaded;
  script.onload = async () => {
    loaded = await updateKlarna();
  };
  return loaded;
};
